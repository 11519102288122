import React from 'react';
import styled from 'styled-components';
import { BiError } from 'react-icons/bi';
import Layout from '../components/Layout';
import { Link } from 'gatsby';

const Title = styled.h1`
  font-size: 3rem;
  padding: 2rem 1rem;
`;

const Wrap = styled.div`
  min-height: 70vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;

  align-items: center;
`;

const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <div className="section">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <Wrap>
              <Row>
                <BiError size={80} color="#F90" />
                <Title>Nie znaleziono</Title>
              </Row>
              <p>
                Szukana strona nie została znaleźiona... bardzo na przykro :(
              </p>
              <p style={{ marginTop: '1rem' }}>
                Kliknij <Link to="/">tutaj</Link> aby wrócić na stronę główną.
              </p>
            </Wrap>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
